import FireIcon from "@heroicons/react/16/solid/FireIcon";
import MapPinIcon from "@heroicons/react/16/solid/MapPinIcon";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import BookmarkFilledIcon from "@heroicons/react/24/solid/BookmarkIcon";
import GlobeAsiaAustraliaIcon from "@heroicons/react/24/solid/GlobeAsiaAustraliaIcon";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import { styled as styling } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Link from "next/link";
import Color from "../../../assets/colors";

export const LinkWrapper = styling(Link)({
  textDecoration: "none",
  color: Color.black,
  width: "100%",
});

export const TooltipWrapper = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `1.5px solid ${Color.black}`,
    borderRadius: "8px",
    padding: "4px 8px",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 10,
    marginTop: "20px",
    textWrap: "nowrap",
  },

  "@media (max-width: 1279px)": {
    display: "none",
  },
}));

export const CardWrapper = styling("div")((props) => ({
  backgroundColor: Color.white,
  border: `1px solid ${Color.grey}`,
  borderRadius: "8px",

  display: "grid",
  position: "relative",

  maxWidth: "900px",
  width: "100%",

  overflow: "clip",

  transition: "border 0.125s ease-in-out",

  ...(props.$highlight && {
    border: `1px solid ${Color.hiredlyPurple}`,
  }),

  ...(props.$spotlight && {
    border: `1px solid ${Color.buttonGreen}`,
  }),

  ...(props.$selected && {
    border: `1px solid ${Color.hiredlyPurple}`,
    boxShadow: `0 0 0 1px ${Color.hiredlyPurple}`,
  }),

  ":hover": {
    border: `1px solid ${Color.hiredlyPurple}`,
    boxShadow: `0 0 0 1px ${Color.hiredlyPurple}`,
    animation: "none",
  },

  ".shrink-on-hover": {
    width: "100%",
    transition: "width 0.125s ease-in-out",
    transitionDelay: "0.05s",
  },

  "@media (min-width: 1280px)": {
    "&:hover": {
      ".shrink-on-hover": {
        borderRight: `1px solid ${Color.black}`,
        width: "calc(100% - 90px)",
      },
    },
  },

  "@media (max-width: 640px)": {
    maxWidth: "100%",

    ...(props.$highlight && {
      border: `2px solid ${Color.hiredlyPurple}`,
    }),

    ...(props.$spotlight && {
      border: `2px solid ${Color.buttonGreen}`,
    }),
  },
}));

export const CardVisibleLayer = styling("div")({
  gridArea: "1 / 1",
  zIndex: 1,

  display: "grid",
});

export const TopContainer = styling("div")((props) => ({
  backgroundColor: Color.white,

  display: "grid",
  gridTemplateColumns: "424px 1fr",

  padding: "0.625rem 1rem 0.5rem",

  "@media (max-width: 640px)": {
    gridTemplateColumns: "1fr",

    minHeight: "auto",
    padding: props.$boosted ? "1.25rem 1rem 0.875rem" : "1rem 1rem 0.875rem",
    width: "100%",
    overflow: "hidden",
  },
}));

export const HighlightedContainer = styling("div")({
  gridColumn: "1 / -1",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",

  height: "28px",
  margin: "0 0 0.25rem",
});

export const VisibilityContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
});

export const JobDetailsContainer = styling("div")({
  gridColumn: 1 / 2,

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  minHeight: "56px",

  "@media (max-width: 640px)": {
    gridColumn: "1 / -1",

    flexDirection: "row",
    alignItems: "flex-start",
    gap: "0.5rem",

    minHeight: "fit-content",
    margin: "0 0 0.5rem",
  },
});

export const JobSubDetailsContainer = styling("div")({
  display: "flex",
  gap: "0.625rem",
});

export const JobCardSubContainer = styling("div")({
  display: "flex",
  flexDirection: "column",

  "@media (max-width: 640px)": {
    gap: "0.75rem",
  },
});

export const CompanyDetailsContainer = styling("div")({
  gridColumn: 1 / 2,

  display: "grid",
  gridTemplateColumns: "56px 1fr 24px",
  alignItems: "flex-start",

  gap: "1rem",

  "@media (max-width: 640px)": {
    gridTemplateColumns: "28px 1fr",
    alignItems: "center",

    gap: "0.5rem",
  },
});

export const BottomContainer = styling("div")((props) => ({
  gridColumn: "1 / -1",

  backgroundColor: Color.backgroundGrey,

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",

  minHeight: "40px",
  padding: "0.5rem 1rem",

  ...(props.$jobSlotMax && {
    backgroundImage: `linear-gradient(90deg, #B0CD72 -0.98%, ${Color.buttonGreen} 77.2%)`,
    backgroundSize: "100% 600%",
    animation: "highlightGradientLoop 1.75s linear infinite",
  }),

  "@media (max-width: 640px)": {
    padding: "0.75rem 1rem",
    gap: "0.5rem",
  },
}));

export const BottomChipsContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.375rem",

  "@media (max-width: 640px)": {
    flexWrap: "wrap",
  },
});

export const MobileChipContainer = styling("div")((props) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: "0.4375rem",
}));

export const MobileApplyContainer = styling("div")({
  gridColumn: "1 / -1",

  backgroundColor: Color.backgroundGrey,
  borderTop: `1px solid ${Color.grey}`,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  minHeight: "40px",
  width: "100%",
  padding: "0.75rem 1rem",

  "@media (max-width: 640px)": {
    position: "relative",
    overflow: "hidden",
  },
});

export const HighlightContainer = styling("span")((props) => ({
  background: props.$highlight ? Color.hiredlyPurple : Color.buttonGreen,

  gridArea: "1 / 1",
  zIndex: 2,

  width: "8px",

  ...(props.$spotlight && {
    "@keyframes highlightGradientLoop": {
      "0%": {
        backgroundPosition: "0% 100%",
      },
      "100%": {
        backgroundPosition: "0% 0%",
      },
    },

    backgroundImage: `linear-gradient(0deg,transparent,transparent,#B0CD72,transparent,transparent)`,
    backgroundSize: "100% 600%",
    animation: "highlightGradientLoop 1.75s linear infinite",
  }),

  "@media (max-width: 640px)": {
    height: "8px",
    width: "100%",

    backgroundSize: "100% 600%",
  },
}));

export const VisibilityChip = styling("span")((props) => ({
  color: Color.white,
  borderRadius: "0.3125rem",

  display: "flex",
  alignItems: "center",

  height: "1.5rem",
  padding: "0 0.3125rem",

  ...(props.$highlight && {
    backgroundColor: Color.hiredlyPurple,
  }),

  ...(props.$spotlight && {
    background: "linear-gradient(242deg, #6EC7CD 14.33%, #B0CD72 76.81%)",
  }),

  ...(props.$global && {
    backgroundColor: "#F9CA10",
    borderRadius: "6.25rem",

    gap: "0.25rem",

    fontSize: "0.625rem",
    fontWeight: "700",
    lineHeight: "1.25rem",
    letterSpacing: "1.5px",
    textTransform: "uppercase",

    height: "1.5rem",
    padding: "0 0.5rem 0 0.375rem",
  }),
}));

export const MobileDetailChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0.25rem",

  fontSize: "0.75rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.3125rem",
  padding: "0 0.625rem",

  ...(props.type === "jobType" && {
    color: Color.blue,
    backgroundColor: "rgba(33, 61, 181, 0.14)",
  }),
  ...(props.type === "state" && {
    color: "rgba(129, 155, 72, 1)",
    backgroundColor: "rgba(129, 155, 72, 0.14)",
  }),
  ...(props.type === "salary" && {
    color: "rgba(155, 72, 72, 1)",
    backgroundColor: "rgba(155, 72, 72, 0.14)",
  }),
}));

export const JobTypeChip = styling("span")((props) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: props.$jobSlotMax ? Color.white : "rgba(33, 61, 181, 0.14)",
  color: Color.blue,
  borderRadius: "6.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  textWrap: "nowrap",

  height: "1.5rem",
  padding: "0 0.625rem",
}));

export const KeywordChip = styling("span")((props) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: props.$jobSlotMax ? Color.white : Color.lightHiredlyPurple,
  color: "rgba(0, 0, 0, 0.60)",
  border: "0.5px solid rgba(0, 0, 0, 0.60)",
  borderRadius: "0.25rem",

  fontSize: "0.75rem",
  fontWeight: "300",
  lineHeight: "1.25rem",
  textWrap: "nowrap",

  height: "1.25rem",
  padding: "0.375rem",
}));

export const JobTitleText = styling("h2")({
  color: Color.black,

  display: "-webkit-box",

  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.1px",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  wordBreak: "break-word",
  overflowWrap: "break-word",
  overflow: "hidden",

  margin: "0 0.5rem 0.625rem 0",

  "@media (max-width: 640px)": {
    margin: "0",
  },
});

export const JobDetailText = styling("h3")({
  color: Color.textMediumEmphasis,

  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "20px",

  margin: "0",
});

export const JobCompanyText = styling("h4")({
  color: Color.black,

  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
  overflow: "hidden",

  width: "100%",
  margin: "0",

  "@media (max-width: 640px)": {
    color: Color.textMediumEmphasis,

    fontSize: "0.75rem",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",

    WebkitLineClamp: 1,
    whiteSpace: "nowrap",
  },
});

export const PostedTimeText = styling("p")((props) => ({
  color: props.$jobSlotMax ? Color.white : Color.darkGrey,

  fontSize: "0.75rem",
  lineHeight: "1.25rem",
  textWrap: "nowrap",

  margin: "0",

  "@media (max-width: 640px)": {
    color: Color.darkGrey,
    fontSize: "0.625rem",
    fontWeight: "500",
    textAlign: "right",
  },
}));

export const HiddenApplyButton = styling(ButtonBase)({
  gridArea: "1 / 1",
  justifySelf: "flex-end",

  backgroundColor: Color.buttonGreen,
  color: Color.white,

  fontFamily: "Inter",
  fontSize: "0.8125rem",
  fontWeight: "700",
  letterSpacing: "0.06px",
  textTransform: "capitalize",
  textAlign: "center",

  width: "90px",
  padding: "0 0.75rem",

  "&:disabled": {
    backgroundColor: Color.grey,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});

export const BookmarkButton = styling(ButtonBase)((props) => ({
  borderRadius: "0.25rem",
  flexShrink: 0,
  width: "24px",

  transition: "all 0.15s ease-in-out",

  "&:disabled": {
    pointerEvents: "none",
    cursor: "not-allowed",
    opacity: "0.25",
  },
}));

export const MobileApplyButton = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.375rem",

  color: Color.white,
  backgroundColor: Color.buttonGreen,
  border: "2px solid" + Color.black,
  borderRadius: "6.25rem",

  fontSize: "0.875rem",
  fontWeight: 700,
  textTransform: "capitalize",
  whiteSpace: "nowrap",

  height: "36px",
  width: "107px",
  padding: "0 1rem",

  ...(props?.disabled && {
    border: "2px solid" + Color.darkGrey,
    backgroundColor: Color.grey,
    color: Color.white,
  }),
}));

export const CompanyLogo = styling("img")((props) => ({
  border: "0.5px solid rgba(0, 0, 0, 0.38)",
  borderRadius: "0.375rem",

  objectFit: props.$placeholder ? "fit" : "contain",

  width: "56px",
  height: "56px",

  "@media (max-width: 640px)": {
    width: "28px",
    height: "28px",
  },
}));

export const SaveIcon = styling(BookmarkFilledIcon)({
  color: Color.white,
  stroke: Color.hiredlyPurple,
  strokeWidth: "1.5",

  height: "24px",
  width: "24px",

  transition: "all 0.15s ease-in-out",
  "&:hover": {
    strokeWidth: "2",
  },
});

export const UnsaveIcon = styling(BookmarkFilledIcon)({
  color: Color.hiredlyPurple,
  strokeWidth: "1.5",
  height: "24px",
  width: "24px",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    strokeWidth: "2",
  },
});

export const HighlightIcon = styling(FireIcon)({
  height: "14px",
  width: "14px",
});

export const SpotlightIcon = styling(SparklesIcon)({
  height: "14px",
  width: "14px",
});

export const GlobalIcon = styling(GlobeAsiaAustraliaIcon)({
  height: "14px",
  width: "14px",
});

export const DollarIcon = styling(AttachMoneyIcon)({
  color: "rgba(0, 0, 0, 0.60)",
  height: "20px",
  width: "auto",
});

export const LocationIcon = styling(MapPinIcon)({
  color: "rgba(0, 0, 0, 0.60)",
  height: "20px",
  width: "auto",
});

export const ArrowIconStyled = styling(ArrowTopRightOnSquareIcon)({
  height: "20px",
  width: "20px",
});

export const DividerStyled = styling(Divider)((props) => ({
  background: props.$jobSlotMax ? Color.white : Color.lightGrey,
  height: "1rem",
}));
